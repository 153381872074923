export const CategoryNames = [
    'Tüm Kategoriler',
    'Elektronik',
    'Ev ve Yaşam',
    'Evcil Hayvan',
    'Kitap',
    'Oyuncak',
    'Spor',
    'Çiçek (120)',
    'Hediye',
    'Moda, Aksesuar',
    'Ofis, Kırtasiye',
    'Parfüm',
    'Kişisel Bakım',
    'Petshop'
]