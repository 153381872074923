import * as React from "react";

function SvgYt(props) {
  return (
    <svg width={34.222} height={23.917} {...props}>
      <path
        d="M33.508 3.693A4.292 4.292 0 0030.53.715C27.863 0 17.13 0 17.13 0S6.418-.016 3.723.715A4.292 4.292 0 00.745 3.693 44.666 44.666 0 000 11.974a44.564 44.564 0 00.745 8.249 4.292 4.292 0 002.978 2.978c2.665.716 13.4.716 13.4.716s10.707 0 13.4-.716a4.292 4.292 0 002.978-2.978 44.557 44.557 0 00.713-8.249 44.648 44.648 0 00-.706-8.281zM13.695 17.096V6.836l8.94 5.138z"
        fill="red"
      />
    </svg>
  );
}

export default SvgYt;
