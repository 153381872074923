export const Products = [
    {
        name: "Z6 Akıllı Saat Kameralı Konuşma Garantili Sim Kartlı Bluetooth",
        price: 399.9,
        image: "watch",
        category: "Elektronik",
    },
    {
        name: "Calvin Klein Euphoria EDP 100 ml Kadın Parfüm",
        price: 145.61,
        image: "cologne",
        category: "Parfüm",
    },
    {
        name: "Gold Çizgili Vazoda 2 Dal Orkide Çiçeği",
        price: 249.9,
        image: "adamotu",
        category: "Çiçek (120)",
    },
    {
        name: "925 Ayar Gümüş Çeyreklik Bileklik (Y)",
        price: 329.9,
        image: "bracelet",
        category: "Hediye",
    },
    {
        name:
            "Sailor SD-3160 Siyah Dağcı Seyahat Sırt Çantası 55 LT Outdoor-Trekking",
        price: 499.9,
        image: "bag",
        category: "Spor",
    },
    {
        name: "Büyük Boy Metal Osmanlı Bizans Satranç Takımı Parlak ve Cev...",
        price: 19.9,
        image: "chess",
        category: "Spor",
    },
    {
        name: "130 Cm Papyonlu Büyük Ayıcı (%100 Yerli)",
        price: 89.9,
        image: "pinkpanda",
        category: "Hediye",
    },
    {
        name: "925 Ayar Gümüş Dualı İsimli Nazar İgnesi",
        price: 29.9,
        image: "hamza",
        category: "Hediye",
    },
    {
        name: "Özel Tatlar Çikolata Kutusu 300 gr",
        price: 59.9,
        image: "chocolate",
        category: "Hediye",
    },
    {
        name: "Kucak Dolusu Aşk 25 Kırmızı Gül Buketi",
        price: 199.9,
        image: "gunsnroses",
        category: "Hediye",
    },
    {
        name: "Z6 Akıllı Saat Kameralı Konuşma Garantili Sim Kartlı Bluetooth",
        price: 399.9,
        image: "watch",
        category: "Elektronik",
    },
    {
        name: "Calvin Klein Euphoria EDP 100 ml Kadın Parfüm",
        price: 145.61,
        image: "cologne",
        category: "Parfüm",
    },
    {
        name: "Gold Çizgili Vazoda 2 Dal Orkide Çiçeği",
        price: 249.9,
        image: "adamotu",
        category: "Çiçek (120)",
    },
    {
        name: "925 Ayar Gümüş Çeyreklik Bileklik (Y)",
        price: 329.9,
        image: "bracelet",
        category: "Hediye",
    },
    {
        name:
            "Sailor SD-3160 Siyah Dağcı Seyahat Sırt Çantası 55 LT Outdoor-Trekking",
        price: 499.9,
        image: "bag",
        category: "Spor",
    },
    {
        name: "Büyük Boy Metal Osmanlı Bizans Satranç Takımı Parlak ve Cev...",
        price: 19.9,
        image: "chess",
        category: "Spor",
    },
    {
        name: "130 Cm Papyonlu Büyük Ayıcı (%100 Yerli)",
        price: 89.9,
        image: "pinkpanda",
        category: "Hediye",
    },
    {
        name: "925 Ayar Gümüş Dualı İsimli Nazar İgnesi",
        price: 29.9,
        image: "hamza",
        category: "Hediye",
    },
    {
        name: "Özel Tatlar Çikolata Kutusu 300 gr",
        price: 59.9,
        image: "chocolate",
        category: "Hediye",
    },
    {
        name: "Kucak Dolusu Aşk 25 Kırmızı Gül Buketi",
        price: 199.9,
        image: "gunsnroses",
        category: "Hediye",
    },
];
